import FormBase from 'components/form-base/FormBase';
import { Error, FieldRow } from 'components/form-base/styled';
import { useInternshipSettings } from 'contexts';
import DOMPurify from 'dompurify';
import { TextField } from 'kl-b2c-ui-kit';
import React, { FC, useEffect } from 'react';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SubscriptionForm as SubscriptionFormType } from 'types';

interface RegistrationFormProps {
    submit: (data: FieldValues) => void;
}

const SubscriptionForm: FC<RegistrationFormProps> = ({ submit }) => {
    const { t } = useTranslation('pages/home');
    const { subscriptionFormSetting, addInternshipResponseLoading } = useInternshipSettings();

    const {
        control,
        formState: { errors },
        reset,
    } = useFormContext<SubscriptionFormType>();

    useEffect(
        () => () => {
            reset();
        },
        []
    );

    return (
        <FormBase
            submitButtonWidth={200}
            gap={'24px'}
            policies={[
                {
                    name: 'agreement.touCheckBoxIsSelected',
                    label: t('tou', {
                        touLink: `${window.location.origin}/terms-of-use`,
                    }),
                    required: true,
                },
                {
                    name: 'agreement.marketingIsSelected',
                    label: t('marketing', {
                        confirmationLink: `${window.location.origin}/privacy-policy#personalDataInternshipProcessing`,
                        ppLink: '/privacy-policy',
                    }),
                },
            ]}
            submit={submit}
            loading={addInternshipResponseLoading}
            submitLabel={t('subscribe')}
        >
            {subscriptionFormSetting?.nameIsRequired && (
                <Controller
                    name={'name'}
                    control={control}
                    rules={{
                        required: t('required-field'),
                        maxLength: { value: 50, message: t('max-length', { value: 50 }) },
                    }}
                    render={({ field: { onChange, value } }) => (
                        <FieldRow>
                            <TextField
                                fullWidth
                                value={value}
                                type={'text'}
                                variant={'border'}
                                transparent
                                placeholder={t('name')}
                                onChange={(value) => onChange(value)}
                                error={!!errors['name']}
                            />
                            {errors?.name && (
                                <Error>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                errors?.name?.message ?? DOMPurify.sanitize(t('validations:default')),
                                        }}
                                    />
                                </Error>
                            )}
                        </FieldRow>
                    )}
                />
            )}
            <Controller
                name={'email'}
                control={control}
                rules={{
                    required: t('required-field'),
                    maxLength: { value: 255, message: t('max-length', { value: 255 }) },
                    pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: t('email-format'),
                    },
                }}
                render={({ field: { onChange, value } }) => (
                    <FieldRow>
                        <TextField
                            fullWidth
                            value={value}
                            type={'text'}
                            variant={'border'}
                            transparent
                            placeholder={t('email')}
                            onChange={(value) => onChange(value)}
                            error={!!errors['email']}
                        />
                        {errors?.email && (
                            <Error>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: errors?.email?.message ?? DOMPurify.sanitize(t('validations:default')),
                                    }}
                                />
                            </Error>
                        )}
                    </FieldRow>
                )}
            />
        </FormBase>
    );
};

export default SubscriptionForm;
