import React, { FC } from 'react';
import { Brackets, Container, Content, CPlus, Description, Go, VacancyButton } from './styled';
import { BaseWrapper } from 'styles/common';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { CareerVacancyLink } from 'consts/header-menu';

interface IProps {
    description: string;
}

export const CareerPortal: FC<IProps> = ({ description }) => {
    const { t } = useTranslation('pages/home');
    return (
        <Container id="career_link">
            <BaseWrapper>
                <Content>
                    <Brackets src={process.env.PUBLIC_URL + '/assets/images/brackets.png'} />
                    <Go src={process.env.PUBLIC_URL + '/assets/images/go.png'} />
                    <CPlus src={process.env.PUBLIC_URL + '/assets/images/cplus.png'} />
                    <Description dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
                    <VacancyButton as={'a'} href={CareerVacancyLink} target="_blank" type="button">
                        {t('to-vacancy')}
                    </VacancyButton>
                </Content>
            </BaseWrapper>
        </Container>
    );
};
