import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { headerMenuItems, footerModel, LS_KEYS, headerMenuItemsServiceDesc, headerMenuItemsClosed } from 'consts';
import Header from '../../components/header/Header';
import Main from '../../components/main/Main';
import Footer from '../../components/footer/Footer';
import { DirectionsProvider, useInternshipSettings } from 'contexts';
import { StorageService } from 'services';
import CookieBanner from 'components/cookie-banner/CookieBanner';
import { HeaderMenu } from 'types/header-menu';
import { useTranslation } from 'react-i18next';

const App = () => {
    const { t } = useTranslation('pages/home');
    const storageService = new StorageService();
    const [cookieBannerVisible, setCookieBannerVisible] = useState<boolean>(
        !storageService.getItem<boolean>(LS_KEYS['cookiesSuppressed'])
    );

    const isServiceDesk = () => window.location.pathname.includes('service-desk');
    const { internshipSettings, subscriptionFormSetting, showSubscriptionModal, showRegistrationModal } =
        useInternshipSettings();

    const registration: HeaderMenu = {
        title: t('register'),
        dataAtSelector: 'sendOrder',
        onclick: showRegistrationModal,
    };

    const subscription: HeaderMenu = {
        title: t('subscribe'),
        dataAtSelector: 'leaveContactLink',
        onclick: showSubscriptionModal,
    };

    const [links, setLinks] = useState(
        isServiceDesk()
            ? [...headerMenuItemsServiceDesc, registration]
            : internshipSettings?.isOpened
            ? [...headerMenuItems, internshipSettings?.isOpened ? registration : subscription]
            : [...headerMenuItemsClosed, internshipSettings?.isOpened ? registration : subscription]
    );

    const location = useLocation();

    useEffect(() => {
        setLinks(
            isServiceDesk()
                ? [...headerMenuItemsServiceDesc, registration]
                : internshipSettings?.isOpened
                ? [...headerMenuItems, internshipSettings?.isOpened ? registration : subscription]
                : [...headerMenuItemsClosed, internshipSettings?.isOpened ? registration : subscription]
        );
    }, [location, internshipSettings, subscriptionFormSetting]);

    return (
        <>
            <Header links={links} />
            <Main>
                <Outlet />
            </Main>
            <Footer model={footerModel} />
            {cookieBannerVisible && (
                <CookieBanner
                    accept={() => {
                        storageService.setItem(LS_KEYS['cookiesSuppressed'], true);
                        setCookieBannerVisible(false);
                    }}
                />
            )}
        </>
    );
};

export default App;
