import styled, { css } from 'styled-components';
import { GetFile, Typography } from 'kl-b2c-ui-kit';
import {
    colorGray,
    colorMenuBackground,
    colorPrimary,
    colorWhite,
    mediaMd,
    mediaSm,
    transitionBase,
} from 'theme/theme-helper-selectors';

export const Container = styled.div<{ darkBg?: boolean }>`
    padding: 140px 0 110px;
    position: relative;

    @media (${mediaSm}) {
        padding: 60px 0 200px;
    }
    ${({ darkBg }) =>
        darkBg &&
        css`
            background-color: rgba(0, 168, 142, 0.1);
        `}
`;

export const DescriptionContainer = styled.div`
    margin: 0 0 60px 0;

    @media (${mediaSm}) {
        margin: 0 0 40px 0;
    }
`;

export const Description = styled.div`
    font-size: 32px;
    line-height: 36px;
    font-weight: 500;
    max-width: 600px;

    @media (${mediaSm}) {
        margin: 0 0 40px 0;
        max-width: initial;
    }
`;

export const Slide = styled.div`
    background: ${colorWhite};
    border-radius: 8px;
    border: 1px solid ${colorPrimary};
    border-bottom: 12px solid ${colorPrimary};
    padding: 26px;
    height: 490px;
    display: flex !important;
    flex-direction: column;
`;

export const SliderContainer = styled.div`
    margin: 0 -12px;

    .slick-arrow {
        width: 53px;
        height: 39px;
    }

    .slick-prev {
        left: -80px;
        transform: translateY(-100%);
    }

    .slick-next {
        right: -80px;
        transform: translateY(-100%);
    }

    .slick-slide {
        padding: 0 12px;
    }
`;

export const DotsContainer = styled.div`
    margin: 45px 0 0 0;
    position: static;
`;

export const DotsList = styled.ul`
    li {
        width: 10px;
        height: 10px;
        background: #1d36532b;
        border-radius: 10px;
        transition: ${transitionBase};

        button {
            &:before {
                visibility: hidden;
            }
        }
    }
    .slick-active {
        width: 70px;
        background: ${colorMenuBackground};
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 7px;

    margin: 0 0 13px 0;

    @media (${mediaSm}) {
        margin: 0 0 21px 0;
    }
`;

export const Avatar = styled.div<{ background: GetFile | File | null }>`
    width: 48px;
    height: 48px;
    background: ${({ background }) => `url(${(background as GetFile)?.fileLink})`};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Position = styled.div`
    font-size: 16px;
    line-height: 20px;
    color: #6a727a;
`;

export const Name = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
`;

export const Content = styled.div<{ autoHeight?: boolean }>`
    height: ${({ autoHeight }) => (autoHeight ? 'auto' : '310px')};
    overflow-y: auto;
    padding: 0 10px 0 0;
    font-size: 18px;
    line-height: 21px;

    b {
        font-weight: 500;
    }

    ::-webkit-scrollbar {
        width: 9px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${colorGray};
        border-radius: 5px;
    }
`;

export const Action = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    cursor: pointer;
    margin-top: auto;

    svg {
        transition: ${transitionBase};
    }

    &:hover {
        svg {
            transform: translateX(5px);
        }
    }
`;

export const ActionText = styled.span`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
`;

export const Coffee = styled.img<{ isClosed?: boolean }>`
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;

    ${({ isClosed }) =>
        isClosed &&
        css`
            top: 600px;
        `}

    @media (${mediaMd}) {
        width: 131px;
        top: auto;
        bottom: 0;
    }
`;

export const Cube = styled.img`
    position: absolute;
    left: 55%;
    top: 5%;
    z-index: -1;

    @media (${mediaMd}) {
        width: 91px;
        top: auto;
        left: auto;
        right: 0;
        bottom: 110px;
    }
`;

export const PersonalDataDisclaimer = styled(Typography).attrs({
    variant: 'p3',
})`
    margin-top: 20px;
`;
