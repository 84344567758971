import styled, { CSSObject } from 'styled-components';
import { Button } from 'kl-b2c-ui-kit';
import { mediaSm, colorWhite, colorPrimary, mediaXs } from 'theme/theme-helper-selectors';

export const BaseWrapper = styled.div<{ styles?: CSSObject }>`
    max-width: 1250px;
    padding: 0 15px;
    width: 100%;
    margin: 0 auto;
    ${({ styles }) => ({ ...styles })};
`;

export const PrimaryButton = styled(Button)`
    padding: 19px 24px;
    font-size: 21px;
    line-height: 24px;
    font-weight: 600;

    @media (${mediaSm}) {
        font-size: 18px;
        line-height: 24px;
        padding: 20px 8px;
    }
`;

export const HeaderButton = styled(Button)`
    padding: 10px 24px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
`;

export const PrimaryLinkButton = styled.a`
    padding: 19px 24px;
    font-size: 21px;
    line-height: 24px;
    font-weight: 500;
    color: ${colorWhite};
    &:hover {
        color: ${colorWhite};
    }

    background-color: ${colorPrimary};
    border-radius: 8px;
    padding: 18px 55px;

    @media (${mediaSm}) {
        font-size: 18px;
        line-height: 24px;
    }
    @media (${mediaXs}) {
        width: 100%;
        text-align: center;
    }
`;
