import styled from 'styled-components';
import { PrimaryLinkButton } from 'styles/common';
import { colorWhite, mediaSm } from 'theme/theme-helper-selectors';

export const Container = styled.div`
    position: relative;
    z-index: 20;
    overflow: hidden;
    color: ${colorWhite};
    background: rgba(46, 54, 64, 1);
`;

export const Content = styled.div`
    position: relative;
    min-height: 562px;
    width: 100%;
    padding: 80px 0px;
    display: flex;
    flex-direction: column;
    padding-right: 170px;
    @media (${mediaSm}) {
        padding: 60px 0px 300px 0px;
    }
    align-items: flex-start;
`;

export const Brackets = styled.img`
    position: absolute;
    left: -210px;
    top: 179px;

    @media (${mediaSm}) {
        left: auto;
        top: auto;
        width: 120px;
        right: -40px;
        bottom: 50px;
    }
`;

export const Go = styled.img`
    position: absolute;
    right: 0px;
    top: 10px;

    @media (${mediaSm}) {
        width: 120px;
        right: auto;
        top: auto;
        left: 50px;
        bottom: 159px;
    }
`;

export const CPlus = styled.img`
    z-index: 10;
    position: absolute;
    right: -170px;
    top: 183px;

    @media (${mediaSm}) {
        right: auto;
        top: auto;
        bottom: 15px;
        left: -30px;
    }
`;

export const Description = styled.p`
    font-size: 72px;
    font-weight: 400;
    line-height: 72px;
    @media (${mediaSm}) {
        font-size: 32px;
        font-weight: 500;
        line-height: 36px;
    }
`;

export const VacancyButton = styled(PrimaryLinkButton)`
    margin-top: 40px;
`;
