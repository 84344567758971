import { gql } from '@apollo/client';

export const ADD_SUBSCRIPTION_FORM_DATA_GQL = gql`
    mutation ($model: AddSubscriptionFormViewModelInput!) {
        subscriptionForms {
            add(model: $model) {
                id
            }
        }
    }
`;

export default ADD_SUBSCRIPTION_FORM_DATA_GQL;
