import { HeaderMenu } from 'types';

export const CareerVacancyLink = 'https://careers.kaspersky.ru/vacancy?page=0';

export const headerMenuItems: HeaderMenu[] = [
    { path: '#directions', title: 'Направление', dataAtSelector: 'headerRegistrationLink' },
    { path: '#calendar', title: 'Календари', dataAtSelector: 'headerCallOfPapersLink' },
    { path: '#reviews', title: 'Отзывы', dataAtSelector: 'headerSponsorsLink' },
    { path: '#FAQ', title: 'FAQ', dataAtSelector: 'headerCodeLink' },
];

export const headerMenuItemsServiceDesc: HeaderMenu[] = [
    { path: '#calendar', title: 'Календари', dataAtSelector: 'headerCallOfPapersLink' },
    { path: '#FAQ', title: 'FAQ', dataAtSelector: 'headerCodeLink' },
];

export const headerMenuItemsClosed: HeaderMenu[] = [
    {
        path: CareerVacancyLink,
        title: 'Вакансии',
        dataAtSelector: 'vacanciesLink',
        externalLink: true,
    },
];
