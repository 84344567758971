import { gql } from '@apollo/client';

export const GET_SUBSCRIPTION_FORM_CONFIG_GQL = gql`
    query getInternshipSettings {
        subscribeSetting {
            settings {
                nameIsRequired
            }
        }
    }
`;

export default GET_SUBSCRIPTION_FORM_CONFIG_GQL;
