import React, { FC, ReactElement } from 'react';
import {
    Container,
    Title,
    BackDrop,
    TitleContainer,
    ActionContainer,
    DateContainer,
    Subtitle,
    CloseBlock,
    DateContainerClosed,
} from './styled';
import { BaseWrapper, PrimaryButton } from 'styles/common';
import { useTranslation } from 'react-i18next';
import { useInternshipSettings } from 'contexts';
import DOMPurify from 'dompurify';

interface IntroProps {
    config: {
        title: string;
        until: Date | null;
        isOpened: boolean;
        subtitle?: string;
        closeTitle?: string;
    };
    introImage: ReactElement;
    secondary?: boolean;
}

const Intro: FC<IntroProps> = ({ config: { title, until, isOpened, subtitle, closeTitle }, secondary, introImage }) => {
    const { t } = useTranslation('pages/home');
    const { showRegistrationModal, showSubscriptionModal } = useInternshipSettings();

    return (
        <Container secondary={secondary}>
            <TitleContainer>
                <BaseWrapper
                    styles={
                        secondary
                            ? { '@media screen and (max-width: 767px)': { width: '72%', margin: 'initial ' } }
                            : {}
                    }
                >
                    <Title isClosed={!isOpened} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }} />
                    {subtitle && <Subtitle>{subtitle}</Subtitle>}
                </BaseWrapper>
                <BackDrop />
            </TitleContainer>
            <BaseWrapper>
                <ActionContainer>
                    {isOpened && (
                        <CloseBlock>
                            <DateContainerClosed>{closeTitle}</DateContainerClosed>
                            <ActionContainer>
                                <PrimaryButton
                                    variant={'outlined'}
                                    color={'primary'}
                                    size={'large'}
                                    onClick={showRegistrationModal}
                                >
                                    {t('register')}
                                </PrimaryButton>
                                {until && (
                                    <DateContainer>
                                        {t('until', {
                                            date: new Date(until).toLocaleString('ru-RU', {
                                                day: 'numeric',
                                                month: 'long',
                                            }),
                                        })}
                                    </DateContainer>
                                )}
                            </ActionContainer>
                        </CloseBlock>
                    )}
                    {!isOpened && (
                        <CloseBlock>
                            <DateContainerClosed>{closeTitle}</DateContainerClosed>
                            <PrimaryButton
                                variant={'outlined'}
                                color={'primary'}
                                size={'large'}
                                onClick={showSubscriptionModal}
                            >
                                {t('leave-contact')}
                            </PrimaryButton>
                        </CloseBlock>
                    )}
                </ActionContainer>
                {introImage}
            </BaseWrapper>
        </Container>
    );
};

export default Intro;
