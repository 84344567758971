import { useQuery } from '@apollo/client';
import GET_REVIEWS_GQL from 'api/queries/getFeedbacks';
import { Icon, ModalElement } from 'kl-b2c-ui-kit';
import React, { FC, createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { BaseWrapper } from 'styles/common';
import { Review as ReviewModel } from 'types';
import Review from './Review';
import {
    Action,
    ActionText,
    Coffee,
    Container,
    Cube,
    Description,
    DescriptionContainer,
    DotsContainer,
    DotsList,
    Slide,
    SliderContainer,
    PersonalDataDisclaimer,
} from './styled';
import PrimaryModal from 'components/modal/PrimaryModal';
import DOMPurify from 'dompurify';

interface ReviewsProps {
    config: {
        description: string;
        darkBg?: boolean;
    };
}

const Reviews: FC<ReviewsProps> = ({ config: { description, darkBg } }) => {
    const [reviews, setReviews] = useState<ReviewModel[]>();
    const [activeReview, setActiveReview] = useState<ReviewModel>();
    const { t } = useTranslation('pages/home');
    const modalRef = createRef<ModalElement>();

    useQuery(GET_REVIEWS_GQL, {
        variables: {
            page: 0,
            size: 50,
        },
        onCompleted: ({
            internsFeedbacks: {
                get: { items },
            },
        }) => {
            setReviews(items);
        },
    });

    if (!reviews || reviews.length === 0) {
        return null;
    }

    const CAROUSEL_SETTINGS: Settings = {
        dots: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <Icon name={'ArrowRightThin'} color={'black'} size={50} />,
        prevArrow: <Icon name={'ArrowLeftThin'} color={'black'} size={50} />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        appendDots: (dots) => (
            <DotsContainer>
                <DotsList> {dots} </DotsList>
            </DotsContainer>
        ),
    };

    return (
        <>
            <Container id="reviews" darkBg={darkBg}>
                <Coffee
                    isClosed={darkBg}
                    src={process.env.PUBLIC_URL + '/assets/images/coffee.png'}
                    alt="Coffee cup image"
                />
                <Cube src={process.env.PUBLIC_URL + '/assets/images/cube.png'} alt="Cube image" />
                <BaseWrapper>
                    <DescriptionContainer>
                        <Description dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
                        <PersonalDataDisclaimer variant="p3">{t('personal-data')}</PersonalDataDisclaimer>
                    </DescriptionContainer>

                    <SliderContainer>
                        <Slider {...CAROUSEL_SETTINGS} ref={(slider) => slider}>
                            {reviews.map((review: ReviewModel) => (
                                <Slide key={review.id}>
                                    <Review review={review} isImpression />
                                    <Action
                                        onClick={() => {
                                            setActiveReview(review);
                                            modalRef?.current?.open();
                                        }}
                                    >
                                        <ActionText>{t('read-review')}</ActionText>
                                        <Icon name={'ArrowRightThin'} color={'primary'} size={20} />
                                    </Action>
                                </Slide>
                            ))}
                        </Slider>
                    </SliderContainer>
                </BaseWrapper>
            </Container>

            <PrimaryModal ref={modalRef}>{activeReview && <Review autoHeight review={activeReview} />}</PrimaryModal>
        </>
    );
};

export default Reviews;
