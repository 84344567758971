import React, { FC } from 'react';
import { NativeNavButton, NativeNavLink } from './styled';
import { HeaderMenu } from 'types';
import { useMobileMenuContext } from 'contexts';

type HeaderLinkProps = HeaderMenu & {
    className?: string;
    level?: number;
    actionClick?: (actionType: string) => void;
};

const HeaderLink: FC<HeaderLinkProps> = (props) => {
    const { title, dataAtSelector, ...rest } = props;
    const { toggleMobileMenu } = useMobileMenuContext();

    if (!rest.onclick)
        return (
            <NativeNavLink
                onClick={() => toggleMobileMenu(false)}
                href={props.path}
                data-at-selector={dataAtSelector}
                target="_blanck"
                {...rest}
            >
                {title}
            </NativeNavLink>
        );
    return (
        <NativeNavButton
            onClick={() => {
                if (rest.onclick) rest.onclick();
                toggleMobileMenu(false);
            }}
            data-at-selector={dataAtSelector}
            {...rest}
        >
            {title}
        </NativeNavButton>
    );
};

export default HeaderLink;
