import React, { FC } from 'react';
import { Direction } from 'types';
import { DirectionCard, DirectionCardTag, DirectionCardName, ArrowContainer, FastTrack } from './styled';
import { Icon } from 'kl-b2c-ui-kit';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface DirectionBlockProps {
    item: Direction;
    isGreen: boolean;
    isClosed: boolean;
    click: () => void;
}

const DirectionBlock: FC<DirectionBlockProps> = ({
    item: {
        name: descriptionName,
        type: { name: typeName },
    },
    isGreen,
    isClosed,
    click,
}) => {
    const { t } = useTranslation('pages/home');
    const navigate = useNavigate();

    return (
        <DirectionCard
            isGreen={isGreen && !isClosed}
            onClick={() => {
                if (isGreen && !isClosed) {
                    return navigate('/service-desk');
                }

                return click();
            }}
        >
            <DirectionCardTag>{typeName}</DirectionCardTag>
            {isGreen && !isClosed && <FastTrack>{t('fast-track')}</FastTrack>}
            <DirectionCardName>{descriptionName}</DirectionCardName>
            <ArrowContainer>
                <Icon name={'ArrowShortRight'} color={'primary'} size={14} />
            </ArrowContainer>
        </DirectionCard>
    );
};

export default DirectionBlock;
