import React, { FC, useState, createRef } from 'react';
import {
    Container,
    Title,
    TitleContainer,
    DescriptionContainer,
    Description,
    DirectionsContainer,
    Brackets,
    Go,
    CPlus,
    ModalTitle,
    DirectionDescription,
    ModalContainer,
    LeftPhone,
    RightPhone,
    Cap,
    SmallPhonesContainer,
} from './styled';
import { BaseWrapper } from 'styles/common';
import DOMPurify from 'isomorphic-dompurify';
import { Direction } from 'types';
import DirectionBlock from './DirectionBlock';
import { ModalElement, IAccordionItem } from 'kl-b2c-ui-kit';
import PrimaryModal from 'components/modal/PrimaryModal';
import Tab from 'components/tab';
import { useTranslation } from 'react-i18next';
import DirectionTeam from './DirectionTeam';
import { useDirections } from 'contexts/directions.context';
import PrimaryAccordion from 'components/accordion/PrimaryAccordion';

interface DirectionsProps {
    config: {
        title: string;
        description: string;
        isClosed?: boolean;
    };
}

const Directions: FC<DirectionsProps> = ({ config: { title, description, isClosed } }) => {
    const [activeDirection, setActiveDirection] = useState<Direction>();
    const modalRef = createRef<ModalElement>();
    const { t } = useTranslation('pages/home');

    const getTabs = () => ({
        tabs: [
            {
                key: 'description',
                isActive: true,
                body: (
                    <DirectionDescription>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(activeDirection?.description || ''),
                            }}
                        />

                        {activeDirection?.link && (
                            <a href={activeDirection.link} target="_blank" rel="noopener noreferrer">
                                {t('show-more')}
                            </a>
                        )}
                    </DirectionDescription>
                ),
                title: <span>{t('direction-description')}</span>,
            },
            {
                key: 'teams',
                body: (
                    <>
                        <DirectionDescription>{t('direction-subtitle')}</DirectionDescription>
                        <PrimaryAccordion
                            items={
                                activeDirection?.internshipTeams.map(
                                    (item) =>
                                        ({
                                            key: item.id,
                                            title: <span>{item.name}</span>,
                                            body: <DirectionTeam team={item} />,
                                        } as IAccordionItem)
                                ) || []
                            }
                        />
                    </>
                ),
                title: <span>{t('direction-teams')}</span>,
            },
        ],
        styles: {
            bodyStyles: `padding: 24px 0 0 0;`,
        },
    });

    const { directions } = useDirections();

    if (!directions || directions.length === 0) {
        return null;
    }

    return (
        <>
            <BaseWrapper styles={{ position: 'relative' }} id="directions">
                <Container>
                    {isClosed ? (
                        <>
                            <LeftPhone src={process.env.PUBLIC_URL + '/assets/images/leftPhone.png'} />
                            <RightPhone src={process.env.PUBLIC_URL + '/assets/images/rightPhone.png'} />
                            <Cap src={process.env.PUBLIC_URL + '/assets/images/cap.png'} />
                        </>
                    ) : (
                        <>
                            <Brackets src={process.env.PUBLIC_URL + '/assets/images/brackets.png'} />
                            <Go src={process.env.PUBLIC_URL + '/assets/images/go.png'} />
                            <CPlus src={process.env.PUBLIC_URL + '/assets/images/cplus.png'} />
                        </>
                    )}
                    {!!title && (
                        <TitleContainer>
                            <Title>
                                {isClosed && (
                                    <SmallPhonesContainer>
                                        <LeftPhone
                                            style={{ display: 'block' }}
                                            src={process.env.PUBLIC_URL + '/assets/images/leftPhone.png'}
                                        />
                                        <RightPhone
                                            style={{ display: 'block' }}
                                            src={process.env.PUBLIC_URL + '/assets/images/rightPhone.png'}
                                        />
                                    </SmallPhonesContainer>
                                )}
                                {DOMPurify.sanitize(title)}
                            </Title>
                        </TitleContainer>
                    )}
                    {!!description && (
                        <DescriptionContainer>
                            <Description
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(description),
                                }}
                            />
                        </DescriptionContainer>
                    )}

                    <DirectionsContainer>
                        {directions?.map((d, index) => (
                            <DirectionBlock
                                key={d.id}
                                item={d}
                                isGreen={index === 0}
                                isClosed={!!isClosed}
                                click={() => {
                                    setActiveDirection(d);
                                    modalRef?.current?.open();
                                }}
                            />
                        ))}
                    </DirectionsContainer>
                </Container>
            </BaseWrapper>

            <PrimaryModal ref={modalRef}>
                {activeDirection && (
                    <>
                        <ModalTitle>{activeDirection.name}</ModalTitle>
                        <ModalContainer>
                            <Tab {...getTabs()} />
                        </ModalContainer>
                    </>
                )}
            </PrimaryModal>
        </>
    );
};

export default Directions;
