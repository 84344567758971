import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { colorWhite, mediaSm } from '../../../../theme/theme-helper-selectors';

const navLinkMixin = css`
    text-decoration: none;
    color: ${colorWhite};

    &:hover {
        color: ${colorWhite};
    }

    @media (${mediaSm}) {
        width: 100%;
    }
`;

export const NativeNavLink = styled.a`
    ${navLinkMixin}
`;

export const NativeNavButton = styled.a`
    ${navLinkMixin}
    border: 2px solid ${colorWhite};
    padding: 10px 24px;
    border-radius: 8px;
    cursor: pointer;
`;

export const RouterNavLink = styled(NavLink)`
    ${navLinkMixin}
`;
