import { useMutation, useQuery } from '@apollo/client';
import { mutationDictionary } from 'api/mutations';
import { queryDictionary } from 'api/queries';
import { SubmitModalRow, SubmitModalMessage } from 'components/form-base/styled';
import PrimaryModal from 'components/modal/PrimaryModal';
import RegistrationForm from 'components/regostration-form/RegistrationForm';
import SubscriptionFormComponent from 'components/subscription-form/SubscriptionForm';
import { REG_TYPE_RESPONSE } from 'consts';
import { ModalContainer, ModalDisclaimer, ModalTitle } from 'containers/pages/home-page/components/directions/styled';
import { Icon, ModalElement } from 'kl-b2c-ui-kit';
import React, { createContext, FC, PropsWithChildren, useContext, useMemo, useRef, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InternshipSettings, Register, SubscriptionFormSetting, SubscriptionForm as SubscriptionFormType } from 'types';
import {} from 'types/subscription-form';

const convertError = (error: string): string => {
    switch (error) {
        case 'courseDoesntHaveValueIntoFormSettings':
            return 'COURSE_DOESNT_HAVE_INTO_FORM_SETTINGS';
        case 'emailAndFirstNameAreRequired':
            return 'EMAIL_AND_FIRSTNAME_ARE_REQUIRED';
        case 'userIsExistingOnThisCourse':
            return 'USER_IS_EXISTING_ON_THIS_COURSE';
        case 'captchaIsNotValid':
            return 'CAPTCHA_IS_NOT_VALID';
        case 'youCanNotUsingAuth':
            return 'YOU_CAN_NOT_USING_AUTH';
        case 'ltiError':
            return 'LTI_ERROR';
        default:
            return 'IS_SERVER_ERROR';
    }
};

interface InternshipSettingsContext {
    internshipSettings: InternshipSettings | null;
    subscriptionFormSetting: SubscriptionFormSetting | null;
    showRegistrationModal: () => void;
    showSubscriptionModal: () => void;
    addInternshipResponseLoading?: boolean;
}

const InternshipSettingsContext = createContext<InternshipSettingsContext>({} as InternshipSettingsContext);

const InternshipSettingsProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const [internshipSettings, setInternshipSettings] = useState<InternshipSettings | null>(null);
    const [subscriptionFormSetting, setSubscriptionFormSetting] = useState<SubscriptionFormSetting | null>(null);
    const [addInternshipResponseLoading, setAddInternshipResponseLoading] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<{ value: boolean | null; message: string }>({
        value: null,
        message: '',
    });
    const modalRef = useRef<ModalElement>(null);
    const modalSubscriptionRef = useRef<ModalElement>(null);
    const { t } = useTranslation('pages/home');

    const methods = useForm<Register>();
    const methodsSubscription = useForm<SubscriptionFormType>();

    useQuery(queryDictionary.GET_REGISTER_FORM_CONFIG, {
        onCompleted: ({ internshipSettings: { settings } }) => {
            setInternshipSettings(settings);
        },
    });

    useQuery(queryDictionary.GET_SUBSCRIPTION_FORM_CONFIG, {
        onCompleted: ({ subscribeSetting: { settings } }) => {
            setSubscriptionFormSetting(settings);
        },
    });

    const [addInternshipResponseMutation] = useMutation(mutationDictionary.ADD_REGISTER_FORM_DATA);
    const [addSubscriptionFormMutation] = useMutation(mutationDictionary.ADD_SUBSCRIPTION_FORM_DATA);

    const showRegistrationModal = () => {
        modalRef.current?.open();
    };

    const showSubscriptionModal = () => {
        modalSubscriptionRef.current?.open();
    };

    const submit = (model: FieldValues) => {
        setAddInternshipResponseLoading(true);
        addInternshipResponseMutation({ variables: { model } })
            .then(() => {
                window.ym(94766054, 'reachGoal', 'SendForm');
                setIsSubmitted({ value: true, message: REG_TYPE_RESPONSE['REG_SUCCESS'] });
            })
            .catch((e: { message: string }) => {
                const mode = convertError(e.message);
                if (mode === 'YOU_CAN_NOT_USING_AUTH') {
                    window.ym(94766054, 'reachGoal', 'SendForm');
                }
                setIsSubmitted({
                    value: mode !== 'YOU_CAN_NOT_USING_AUTH' ? false : true,
                    message: REG_TYPE_RESPONSE[mode],
                });
            })
            .finally(() => setAddInternshipResponseLoading(false));
    };

    const submitSubscription = (model: FieldValues) => {
        setAddInternshipResponseLoading(true);
        addSubscriptionFormMutation({
            variables: { model: { ...model, agreement: { ...model.agreement, privacyPolicyIsSelected: false } } },
        })
            .then(() => {
                window.ym(94766054, 'reachGoal', 'SendForm');
                setIsSubmitted({ value: true, message: REG_TYPE_RESPONSE['REG_SUBSCRIPTION_SUCCESS'] });
            })
            .catch((e: { message: string }) => {
                const mode = convertError(e.message);
                if (mode === 'YOU_CAN_NOT_USING_AUTH') {
                    window.ym(94766054, 'reachGoal', 'SendForm');
                }
                setIsSubmitted({
                    value: mode !== 'YOU_CAN_NOT_USING_AUTH' ? false : true,
                    message: REG_TYPE_RESPONSE[mode],
                });
            })
            .finally(() => setAddInternshipResponseLoading(false));
    };

    const memoValue = useMemo(
        () => ({
            internshipSettings,
            subscriptionFormSetting,
            showRegistrationModal,
            showSubscriptionModal,
            addInternshipResponseLoading,
        }),
        [internshipSettings, subscriptionFormSetting, addInternshipResponseLoading]
    );

    return (
        <InternshipSettingsContext.Provider value={memoValue}>
            {children}
            <PrimaryModal ref={modalRef}>
                {isSubmitted.value === null ? (
                    <>
                        <ModalTitle>
                            {t('internship-registration')}
                            <ModalDisclaimer>{t('internship-disclaimer')}</ModalDisclaimer>
                        </ModalTitle>
                        <ModalContainer>
                            <FormProvider {...methods}>
                                <RegistrationForm submit={submit} />
                            </FormProvider>
                        </ModalContainer>
                    </>
                ) : (
                    <>
                        {isSubmitted.value === false && <ModalTitle>{t('something-wrong')}</ModalTitle>}
                        <ModalContainer>
                            {isSubmitted.value === true ? (
                                <SubmitModalRow>
                                    <Icon name={'Info'} size={50} color={'primary'} />
                                    <SubmitModalMessage>{isSubmitted.message}</SubmitModalMessage>
                                </SubmitModalRow>
                            ) : (
                                <SubmitModalRow>
                                    <Icon name={'Cross'} size={50} color={'error'} />
                                    <SubmitModalMessage>{isSubmitted.message}</SubmitModalMessage>
                                </SubmitModalRow>
                            )}
                        </ModalContainer>
                    </>
                )}
            </PrimaryModal>
            <PrimaryModal ref={modalSubscriptionRef} maxWidth="584px">
                {isSubmitted.value === null ? (
                    <>
                        <ModalTitle>
                            {t('subscribe-user')}
                            <ModalDisclaimer>{t('internship-disclaimer')}</ModalDisclaimer>
                        </ModalTitle>
                        <ModalContainer>
                            <FormProvider {...methodsSubscription}>
                                <SubscriptionFormComponent submit={submitSubscription} />
                            </FormProvider>
                        </ModalContainer>
                    </>
                ) : (
                    <>
                        {isSubmitted.value === false && <ModalTitle>{t('something-wrong')}</ModalTitle>}
                        <ModalContainer>
                            {isSubmitted.value === true ? (
                                <SubmitModalRow>
                                    <Icon name={'Info'} size={50} color={'primary'} />
                                    <SubmitModalMessage>{isSubmitted.message}</SubmitModalMessage>
                                </SubmitModalRow>
                            ) : (
                                <SubmitModalRow>
                                    <Icon name={'Cross'} size={50} color={'error'} />
                                    <SubmitModalMessage>{isSubmitted.message}</SubmitModalMessage>
                                </SubmitModalRow>
                            )}
                        </ModalContainer>
                    </>
                )}
            </PrimaryModal>
        </InternshipSettingsContext.Provider>
    );
};

export const useInternshipSettings = () => useContext(InternshipSettingsContext);

export default InternshipSettingsProvider;
