import { gql } from '@apollo/client';

export const GET_HOMEPAGE_SETTINGS_GQL = gql`
    query getHomepageSettings {
        homepageSettings {
            settings {
                introTitle
                directionsTitle
                directionsDescription
                traineeTitle
                traineeDescription
                careerStartTitle
                careerStartDescription
                titleToCareerPortal
                introDescription
            }
        }
    }
`;

export default GET_HOMEPAGE_SETTINGS_GQL;
